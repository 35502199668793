<template>
  <Modal
    name="prize_popup-1"
    size="lg"
    :title="prize === 5 ? 'ваш гарантированный приз' : 'Вы выиграли приз!'"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <div class="modal__prize">
        <img
          v-if="prize"
          class="modal__prize-img modal__prize-img"
          :src="
            require(`../../assets/images/prizes/user-prizes/prize_${prize}-big.png`)
          "
          alt=""
        />
      </div>
      <div class="modal__text">
        <b
          >Путешествие с&nbsp;видом на&nbsp;горы <br />для всей семьи
          на&nbsp;300&nbsp;000&nbsp;₽</b
        >
        <br />
        <span
          >Для подтверждения статуса победителя необходимо
          в&nbsp;течение&nbsp;2&nbsp;(двух) рабочих дней с&nbsp;момента
          получения данного письма выслать на&nbsp;адрес электронной почты
          <br />Организатора
          <a href="mailto:info@nuri-promo.ru" class="_red _undeline-link"
            >info@nuri-promo.ru</a
          >
          следующие достоверные документы/сведения о&nbsp;себе:</span
        >
        <ul>
          <li v-for="(li, i) in list" :key="i">
            <i>{{ i + 1 }}</i>
            <span v-html="li.text"></span>
          </li>
        </ul>
      </div>

      <div class="modal__footer">
        <router-link
          class="btn btn--primary w-100"
          @click.native="closeModal('prize_popup-1')"
          :to="{ name: 'Profile', hash: '#form' }"
          >заполнить форму</router-link
        >
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      prize: 0,
      prizeTitle: null,
      list: [
        {
          text: "Фамилия, имя, отчество;",
        },
        {
          text: "Контактный номер телефона;",
        },
        {
          text: "Дата рождения;",
        },
        {
          text: "Фото/скан страниц своего паспорта гражданина&nbsp;РФ (разворот с&nbsp;фотографией и&nbsp;страница с&nbsp;информацией о&nbsp;последнем месте регистрации);",
        },
        {
          text: "Фото/скан свидетельства ИНН;",
        },
        {
          text: 'Фото/скан подписанного согласия на&nbsp;обработку персональных данных <a href="/docs/policy.pdf" target="_blank">nuri-promo.ru/soglasiye.pdf;</a>',
        },
        {
          text: "Реквизиты банковской карты;",
        },

        {
          text: "Иную необходимую информацию по&nbsp;запросу Организатора.",
        },
      ],
    };
  },

  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen(event) {
      this.prize = event.params.prize;
      this.prizeTitle = event.params.prizeTitle;
    },
  },
};
</script>

<style lang="scss" scoped></style>
