<template>
  <div id=""></div>
</template>

<script>
export default {
  name: "Apmcheck",

  data() {
    return {
      ready: false,
    };
  },

  methods: {},
  // created() {},
  mounted() {
    this.ready = true;
  },

  components: {},
};
</script>

<style lang="scss">
#promo-tools {
  .popup {
    display: flex;
    align-items: flex-start;
    padding-top: rem(0px);
    padding-bottom: rem(50px);
    background: rgba(#fff, 0.2);
    backdrop-filter: blur(6px);
    &::-webkit-scrollbar {
      width: rem(5px);
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #ececec;
      border-radius: 13px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: $grey;
      transition: all 0.3s ease-in-out;
    }
    &__container {
      width: rem(520px);
      padding: rem(30px) rem(20px);
      height: auto;
      border: none;
      background: #dec78e;
      border-radius: 0 0 rem(30px) rem(30px);
    }
    .check-grabber {
      color: $brown;
      &__fail-icon {
        display: none;
        @include size(#{rem(75px)});
        font-size: 0;
        background: url("../assets/images/icons/error.svg") no-repeat center;
        background-size: contain;
      }
      &__title {
        margin-bottom: rem(0px);
        font-family: $alt-font;
        letter-spacing: 0.02em;
        font-size: rem(30px);
        line-height: 1;
        font-weight: normal;
      }
      &__sub-title {
        font-size: rem(20px);
        line-height: rem(20px);
        font-family: $reg;
        text-transform: none;
        strong {
          display: block;
          margin-bottom: rem(20px);
          font-family: $alt-font;
          letter-spacing: 0.02em;
          font-size: rem(30px);
          line-height: 1;
          font-weight: normal;
          opacity: 1;
        }
        &:empty {
          display: none;
        }
      }
      &__form {
        margin-top: rem(20px);
        grid-row-gap: $field-offset-sm;
      }
      &__photos {
        width: 100%;
        display: flex;
        gap: 0 rem(8px);
        margin-top: rem(20px);
        background: url("../assets/images/icons/borders.svg") no-repeat center
          top;
        background-size: 100%;
      }
      &__photo {
        height: 100%;
        border: none;
        &::after {
          content: "";
          display: inline-block;
          width: 0;
          padding-top: 100%;
        }
        &-item {
          flex: 0 1 18%;
          height: rem(57px);
        }
        img {
          object-fit: cover;
          border-radius: rem(10px);
          border: 1px solid rgba($brown, 0.4);
        }
        &-x {
          top: rem(4px);
          right: rem(4px);
          font-size: rem(8px);
          border-radius: 50%;
          background: $brown;
          transform: none !important;
        }
      }
      &__btn-set {
        grid-row-gap: rem(12px);
        margin-top: rem(16px);
        .uploader {
          margin-bottom: rem(10px);
        }
      }
      &__btn-back {
        color: $red;
        &-icon {
          background: $red;
        }
      }
      &__hint {
        color: $red;
        font-family: $reg;
      }
      .btn {
        font-size: rem(16px);
        margin-left: 0;
      }
    }
    &__close {
      top: rem(15px);
      right: rem(15px);
      @include size(#{rem(26px)});
      background: url("../assets/images/icons/close.svg") no-repeat center;
      background-size: rem(16px);
      font-size: 0;
    }
    .btn {
      font-family: $medium;
      position: relative;
      padding: rem(10px) rem(20px);
      border: rem(2px) solid currentColor;
      border-radius: rem(18px);
      box-shadow: none;
      text-transform: uppercase;
      z-index: 1;
      color: #fff;
      background: $brown;
      &::after {
        content: "";
        display: inline-block;
        width: rem(25px);
        height: rem(16px);
        margin-left: rem(10px);
        background: url("data:image/svg+xml,%3Csvg width='25' height='16' viewBox='0 0 25 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.2071 8.70711C24.5976 8.31658 24.5976 7.68342 24.2071 7.29289L17.8431 0.928932C17.4526 0.538408 16.8195 0.538408 16.4289 0.928932C16.0384 1.31946 16.0384 1.95262 16.4289 2.34315L22.0858 8L16.4289 13.6569C16.0384 14.0474 16.0384 14.6805 16.4289 15.0711C16.8195 15.4616 17.4526 15.4616 17.8431 15.0711L24.2071 8.70711ZM0.5 9H23.5V7H0.5V9Z' fill='%23FDCB33'/%3E%3C/svg%3E%0A")
          no-repeat center;
        background-size: contain;
      }
      &:hover {
        opacity: 1;
      }
      &.-red {
        background: $brown;
        border-color: $brown;
        color: #fff;
        &:hover {
          background: #99311a;
          border-color: #99311a;
        }
      }
      &.-grey {
        background: $brown;
        border-color: $brown;
        color: #fff;
        &:hover {
          background: #99311a;
          border-color: #99311a;
        }
      }
    }
    .field {
      position: relative;
      &__label {
        margin-bottom: rem(7px);
        font-size: $field-font-size-sm;
        line-height: 1;
        color: $field-placeholder;
        font-family: $reg;
        transition: 0.4s;
        z-index: 1;
        &-hint {
          font-family: $reg;
          color: $field-placeholder;
        }
      }
      &__input {
        width: 100%;
        height: $field-height-sm;
        padding: $field-padding-checks-lg;
        font-size: $field-font-size-sm;
        max-width: 100%;
        appearance: none;
        font-family: $reg;
        background: #ffffff;
        border-radius: $field-border-radius-sm;
        text-transform: none;
        transition: all 0.3s ease-in-out;
        border: $field-border;
        color: $brown;
        &::placeholder {
          color: $field-placeholder;
          font-family: $reg;
        }
      }
      &__error {
        color: $red;
        font-family: $reg;
        font-size: rem(12px);
        line-height: 1;
        margin-top: rem(2px);
      }
      &__icon {
        @include size(#{rem(22px)});
        font-size: 0;
        background: url("../assets/images/icons/success.svg");
        background-size: contain;
        &.-error {
          background: url("../assets/images/icons/error-small.svg");
          background-size: contain;
        }
      }
      .dp__input_icon {
        display: none;
      }
    }

    .uploader {
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: rem(196px);

        background: rgba(93, 39, 35, 0.2)
          url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 20px;
      }
      &::after {
        content: "Перетащите фото чека сюда или загрузите с компьютера";
        position: absolute;
        top: rem(20px);
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 70%;
        margin: 0 auto rem(12px);
        padding-top: rem(52px);
        background: url("../assets/images/icons/camera.svg") no-repeat center
          top;
        background-size: rem(47px);
        font-size: rem(18px);
        line-height: 1.1;
        font-family: $reg;
      }
      .btn {
        position: absolute;
        left: 50%;
        top: rem(130px);
        transform: translateX(-50%);
        width: 80%;
        margin-bottom: rem(25px);
        color: #fff;
      }
    }
    .check-info {
      display: inline-block;
      vertical-align: middle;
      margin-left: rem(3px);
      cursor: pointer;
    }
    @media (min-width: $md) {
      padding-top: rem(120px);
      &__container {
        width: rem(700px);
        padding: rem(50px) rem(120px);
        border-radius: rem(30px);
        &::before {
          content: "";
          position: absolute;
          right: 0;
          top: 20%;
          transform: translateX(50%);
          display: block;
          @include size(rem(350px), rem(290px));
          // background: url("../assets/images/check.png") no-repeat center;
          background-size: contain;
        }
      }
      &__close {
        top: rem(24px);
        right: rem(24px);
        @include size(#{rem(22px)});
        background-size: contain;
      }
      .check-grabber {
        text-align: center;
        &__fail-icon {
          @include size(#{rem(75px)});
        }
        &__title {
          font-size: rem(50px);
          font-weight: normal;
          line-height: 1;
        }
        &__sub-title {
          margin-top: rem(16px);
          font-size: rem(26px);
          line-height: 1.2;
          strong {
            margin-bottom: rem(30px);
            font-size: rem(50px);
            line-height: 1;
          }
        }
        &__form {
          margin-top: rem(30px);
          grid-row-gap: $field-offset-lg;
        }
        &__btn-set {
          grid-row-gap: rem(20px);
          margin-top: rem(16px);
        }
        &__scanner {
          width: 100%;
          border-radius: rem(20px);
          background: none;
          &-item {
            top: 0;
            left: 0;
            @include size(100% !important);
            border-radius: rem(20px);
          }
        }
        &__photos {
          width: 100%;
          margin-top: rem(30px);
          gap: rem(11.5px);
          margin-left: 0;
        }
        &__photo {
          &-item {
            flex: 0 0 18%;
            height: rem(82px);
            &-x {
              top: rem(4px);
              right: rem(4px);
              font-size: rem(12px);
            }
          }
        }
      }
      .uploader {
        &::before {
          height: rem(220px);
        }
        &::after {
          top: rem(20px);
          width: 55%;
          margin: 0 auto rem(21px);
          padding-top: rem(62px);
          font-size: rem(20px);
          line-height: 1;
          background-size: rem(58px);
        }
        .btn {
          width: 60%;
          top: rem(140px);
        }
      }
      .btn {
        width: 100%;
        padding: rem(25px);
        margin-right: auto;
        font-size: rem(16px);
        border-radius: rem(20px);
      }
      .field {
        width: 100%;
        &__label {
          top: rem(18px);
          left: $field-offset-lg;
          font-size: $field-font-size-lg;
          &-hint {
            font-size: rem(14px);
          }
        }
        &__input {
          height: $field-height-lg;
          padding: $field-padding-checks-lg;
          font-size: $field-font-size-lg;
          border-radius: $field-border-radius-lg;
        }
        &__error {
          font-size: rem(12px);
        }
      }
    }
  }
}
</style>
