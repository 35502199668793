var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"footer__content"},[_c('div',{staticClass:"container"},[_c('ul',{staticClass:"footer__docs"},[_vm._l((_vm.docs),function(item,idx){return _c('li',{key:idx,staticClass:"footer__docs-item"},[_c('a',{staticClass:"footer__docs-link",attrs:{"href":_vm.publicPath + 'docs/' + item.path,"target":"_blank"},on:{"click":function($event){item.gtm
                ? (_vm.gtmEvent('rules', 'click', 'send'),
                  _vm.gtmEvent('rules', 'click', 'internal'),
                  _vm.setMetrika('Клик на кнопку Полные правила акции'),
                  _vm.rulesAdriver())
                : ''}}},[_vm._v(" "+_vm._s(item.name)+" ")])])}),_c('li',{staticClass:"footer__docs-item",on:{"click":function($event){_vm.$modal.show('feedback'),_vm.feedbackAdriver(), _vm.setMetrika('Клик на кнопку Задать вопрос')}}},[_c('span',[_vm._v("обратная связь")])])],2),_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__text"},[_c('p',[_vm._v(" Для участия в акции необходимо: в период с 2 сентября по 3 ноября 2024 г. приобрести в магазинах торговой сети «Монетка» любой чай «Принцесса Нури» и зарегистрировать чек с покупкой на сайте "),_c('a',{staticClass:"_undeline-link",attrs:{"href":"https://www.noori-tea-promo.ru","target":"_blank"}},[_vm._v("noori-tea-promo.ru")]),_vm._v(". Общий срок проведения акции (включая срок выдачи призов): с 2 сентября по 30 ноября 2024 г. Количество призов ограничено. Призы могут отличаться от изображений на рекламных материалах. Информация об организаторе акции, сроках и правилах ее проведения, количестве призов, сроках, месте и порядке вручения призов размещена на сайте "),_c('a',{staticClass:"_undeline-link",attrs:{"href":"https://www.noori-tea-promo.ru","target":"_blank"}},[_vm._v("noori-tea-promo.ru")]),_vm._v(". ")])])
}]

export { render, staticRenderFns }