var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"signup"},[_c('div',{staticClass:"signup__fields"},[_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.first_name) || _vm.errorMessage.first_name,
      },attrs:{"label":"ФИО","field":_vm.first_name,"error":(!_vm.$v.first_name.required && _vm.$v.first_name.$error
          ? 'Обязательное поле'
          : !_vm.$v.first_name.rus && _vm.$v.first_name.$error
          ? 'Введите текст на русском языке'
          : '') || _vm.errorMessage.first_name},on:{"input":function($event){_vm.first_name = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.email) || this.errorMessage.email,
      },attrs:{"label":"E-mail","field":_vm.email,"type":"email","error":(!_vm.$v.email.required && _vm.$v.email.$error
          ? 'Обязательное поле'
          : !_vm.$v.email.email && _vm.$v.email.$error
          ? 'Некорректный e-mail'
          : '') || this.errorMessage.email},on:{"input":function($event){_vm.email = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.phone) || this.errorMessage.phone,
      },attrs:{"label":"Телефон","field":_vm.phone,"type":"tel","mask":'+7(###) ###-##-##',"error":(!_vm.$v.phone.required && _vm.$v.phone.$error
          ? 'Обязательное поле'
          : !_vm.$v.phone.minLength && _vm.$v.phone.$error
          ? 'Минимум 11 символов'
          : '') || this.errorMessage.phone},on:{"input":function($event){_vm.phone = $event}}})],1),_c('div',{staticClass:"signup__checkbox_list"},[_c('checkBox',{class:{
        error: _vm.validationStatus(_vm.$v.agree_pd) || _vm.errorMessage.agree_pd,
      },attrs:{"color":"white","label":`Я согласен(-на) с <a href='${_vm.publicPath}docs/rules.pdf' class='_undeline-link _red' target='_blank'>правилами акции</a>, с&nbsp;<a href='${_vm.publicPath}docs/terms-of-use.pdf' class='_undeline-link _red' target='_blank'>пользовательским соглашением</a> и&nbsp;с&nbsp;<a href='${_vm.publicPath}docs/terms-of-use.pdf' class='_undeline-link _red' target='_blank'>положением о&nbsp;конфиденциальности</a> `,"field":_vm.agree_pd,"error":_vm.errorMessage.agree_pd},on:{"input":function($event){_vm.agree_pd = $event}}})],1),_c('div',{staticClass:"signup__footer"},[_c('button',{staticClass:"btn btn--primary w-100",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_c('span',[_vm._v("Зарегистрироваться")]),_c('img',{staticClass:"loader",attrs:{"src":require("../../assets/img/spinner.svg"),"alt":""}})]),_c('div',{staticClass:"modal__another-option"},[_c('p',[_vm._v(" Уже есть аккаунт? "),_c('a',{staticClass:"_red",attrs:{"href":"#"},on:{"click":function($event){return _vm.showLogInModal()}}},[_vm._v("Войти")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }