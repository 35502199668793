<template>
  <Modal
    name="prize_popup-5"
    size="lg"
    :title="prize === 5 ? 'ваш гарантированный приз' : 'Вы выиграли приз!'"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <div class="modal__prize">
        <img
          v-if="prize"
          class="modal__prize-img modal__prize-img"
          :src="
            require(`../../assets/images/prizes/user-prizes/prize_${prize}-big.png`)
          "
          alt=""
        />
      </div>
      <div class="modal__text">
        <b>{{ prizeTitle }}</b>
        <br />
        <span v-if="prize === 1"
          >Для подтверждения статуса победителя необходимо
          в&nbsp;течение&nbsp;2&nbsp;(двух) рабочих дней с&nbsp;момента
          получения данного письма выслать на&nbsp;адрес электронной почты
          <br />Организатора
          <a href="mailto:info@nuri-promo.ru" class="_red _undeline-link"
            >info@nuri-promo.ru</a
          >
          следующие достоверные документы/сведения о&nbsp;себе:</span
        >
        <span v-else-if="prize === 5"
          >Деньги будут начислены на&nbsp;номер телефона указанный при
          регистрации</span
        >
        <span v-else
          >Чтобы его получить, необходимо заполнить форму
          <br class="hidden-xs" />с&nbsp;данными для доставки приза
          в&nbsp;<router-link
            :to="{ name: 'Profile' }"
            @click.native="closeModal('prize_popup-5')"
            class="_red _undeline-link"
            >личном&nbsp;кабинете</router-link
          ></span
        >
      </div>

      <div class="modal__text-small"></div>
      <div class="modal__footer">
        <button
          class="btn btn--primary w-100"
          @click="closeModal('prize_popup-5')"
        >
          закрыть
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      prize: 0,
      prizeTitle: null,
    };
  },

  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen(event) {
      this.prize = event.params.prize;
      this.prizeTitle = event.params.prizeTitle;
    },
  },
};
</script>

<style lang="scss" scoped></style>
