<template>
  <section class="steps section" id="steps">
    <div class="container">
      <div class="steps__content">
        <h2 class="section-title">как принять участие</h2>
        <div class="steps__list">
          <div class="steps__item" v-for="(item, idx) in steps" :key="idx">
            <div class="steps__item-img-wrapper">
              <img
                :src="require('../assets/images/steps/' + item.img)"
                alt=""
                class="steps__item-img"
              />
            </div>
            <div class="steps__item-content">
              <div class="steps__item-title" v-html="item.title"></div>
              <div class="steps__item-descr" v-html="item.text"></div>
              <button
                v-if="item.button === true"
                @click="toCheckUpload()"
                class="btn btn--primary hidden-xs"
              >
                <span>зарегистрировать чек</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  components: {},
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      steps: [
        {
          img: "step_1.svg",
          title: "Купите любой чай",
          text: "&laquo;Принцесса Нури&raquo; <br>в&nbsp;сети &laquo;Монетка&raquo;",
        },
        {
          img: "step_2.svg",
          title: "Зарегистрируйте чек",
          text: "на сайте",
          button: true,
        },
        {
          img: "step_3.svg",
          title: "выигрывайте призы",
        },
      ],
    };
  },
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.steps {
  position: relative;
  display: flex;
  padding-top: rem(45px);
  padding-bottom: rem(50px);
  background: url("../assets/images/steps/bg-m.svg") no-repeat center bottom;
  background-size: 100%;

  .container {
    position: relative;
    z-index: 99;
  }
  &__content {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    z-index: 9;
  }
  .section-title {
    margin-bottom: rem(15px);
  }
  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }
  &__item {
    display: flex;
    max-width: 100vw;
    width: 100%;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-bottom: rem(30px);
    &:not(:last-of-type) {
      .steps__item-img-wrapper {
        &::before {
          content: "";
          position: absolute;
          left: calc(50% - rem(2px));
          top: 53%;
          width: rem(4px);
          height: rem(80px);
          background-image: url("data:image/svg+xml,%3Csvg width='3' height='165' viewBox='0 0 3 165' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='1.5' y1='6.55671e-08' x2='1.49999' y2='165' stroke='%235D2723' stroke-width='3' stroke-dasharray='6 6'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 60%;
          z-index: -1;
        }
      }
    }
    &-content {
      width: 100%;
    }
    &-img {
      max-height: 100%;
      object-fit: contain;
      &-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-right: rem(14px);
        height: rem(50px);
      }
    }
    &-title {
      margin-bottom: rem(5px);
      font-size: rem(20px);
      line-height: rem(25px);
      font-family: $bold;
      text-transform: uppercase;
    }
    &-descr {
      margin: 0 auto;
      font-size: rem(20px);
      line-height: rem(20px);
      font-weight: 400;
      a,
      span {
        text-decoration: none;
      }
      img {
        display: inline;
      }
    }
    &-monetka {
      height: rem(49px);
      margin: rem(-15px) auto 0;
    }
    .btn {
      margin-top: rem(10px);
    }
  }
  @media (min-width: $sm) {
    padding-top: rem(55px);
    padding-bottom: rem(150px);
    background: linear-gradient(270deg, #fdcb33 4.11%, #ddb12d 100%);
    .section-title {
      margin-bottom: rem(40px);
    }
    &__content {
      padding-top: rem(50px);
      text-align: center;
    }
    &__list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
    }

    &__item {
      flex: 0 0 32%;
      flex-direction: column;
      margin-bottom: 0;
      &-img {
        &-wrapper {
          max-width: 100%;
          height: rem(100px);
          margin-bottom: rem(18px);
          margin-right: 0;
        }
      }
      .btn {
        margin: rem(22px) auto 0;
      }
      &-small {
        min-height: rem(18px);
        margin-bottom: rem(5px);
        margin-left: rem(45px);
        text-align: left;
        font-size: rem(15px);
        line-height: rem(18px);
      }
      &-title {
        margin-bottom: rem(10px);
        font-size: rem(36px);
        line-height: rem(46px);
        letter-spacing: 0.01em;
      }
      &-descr {
        margin: auto;
        font-size: rem(36px);
        line-height: rem(36px);
        white-space: nowrap;
      }
      &:not(:last-of-type) {
        .steps__item-img-wrapper {
          &::before {
            content: "";
            position: absolute;
            left: 85%;
            top: rem(40px);
            width: rem(430px);
            height: rem(41px);
            background-image: url("data:image/svg+xml,%3Csvg width='433' height='45' viewBox='0 0 433 45' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 21.4478C2 21.4478 77.3 -0.871644 146.169 2.3106C201.738 4.87823 218.805 20.3803 293.01 38.3481C351.219 52.4424 431 30.0146 431 30.0146' stroke='%235D2723' stroke-width='4' stroke-linecap='round' stroke-dasharray='10 10'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }
      }
    }
  }
  @media (min-width: $md) {
    .container {
      display: flex;
      align-items: center;
    }
    &__list {
      margin: auto;
      margin-top: 0;
      padding-bottom: 0;
    }
  }
}
@keyframes umbrella {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
