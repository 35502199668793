<template>
  <modal
    :name="name"
    :adaptive="true"
    :scrollable="true"
    height="auto"
    @before-open="beforeOpen"
    :clickToClose="!withoutClosing"
  >
    <div
      class="modal"
      :class="[
        'modal-' + name,
        size ? 'modal--' + size : 'modal--md',
        success ? 'modal-success' : '',
        withoutClosing ? '_withoutClosing' : '',
      ]"
    >
      <div
        class="modal__close"
        v-if="!withoutClosing"
        @click="closeModal(name)"
      ></div>
      <div class="modal__header" v-if="title">
        <h2
          class="modal__title"
          :class="success ? 'modal-success__title' : ''"
          v-html="title"
        ></h2>
      </div>
      <slot name="content"></slot>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
    },
    name: {
      type: String,
    },
    title: {
      type: String,
    },
    success: {
      type: Boolean,
    },
    withoutClosing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showBurger() {
      this.closeModal(this.name);
      let header = document.querySelector(".header");
      if (!header.classList.contains("header--mob_active"))
        header.classList.add("header--mob_active");
      else header.classList.remove("header--mob_active");
    },
    closeModal(name) {
      this.$emit("closeModal", name);
      if (name === "signup") {
        // this.gtmEvent("drop_success", "do", "internal");
      }
    },
    beforeOpen(event) {
      this.$parent.beforeOpen(event);
    },
  },
  mounted() {
    // this.$modal.show("success_modal");
  },
};
</script>

<style lang="scss">
.vm--container {
  overflow: auto;
  z-index: 999999 !important;
  .vm--overlay {
    background: rgba(#fff, 0.2);
    backdrop-filter: blur(6px);
  }
}
.vm--modal {
  min-height: 100vh;
  justify-content: center;
  overflow: visible !important;
  display: flex;
  align-items: flex-end;
  box-shadow: none !important;
  background-color: transparent !important;

  @media (min-width: $md) {
    padding: 0;
    align-items: center;
  }
}

.modal {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  padding: rem(30px) rem(20px) rem(34px);
  background: #dec78e;
  border-radius: 0 0 rem(30px) rem(30px);
  box-shadow: 0px rem(10px) rem(30px) rgba(0, 0, 0, 0.5);
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    display: block;
    width: 100%;
    height: rem(50px);
  }
  &-check_photo {
    min-height: auto;
  }
  &__close {
    cursor: pointer;
    position: absolute;
    top: rem(20px);
    right: rem(20px);
    width: rem(16px);
    height: rem(16px);
    background-image: url("../assets/images/icons/close.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: 0.4s;
    &:hover {
      transform: rotate(90deg);
    }
  }

  &__title {
    margin-bottom: rem(20px);
    font-size: rem(30px);
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 0.05em;
    font-family: $alt-font;
    text-align: center;
    & + .modal__text {
      margin-top: rem(-7px);
    }
  }
  &__subtitle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: rem(-20px);
    margin-bottom: rem(10px);
    font-family: $bold;
    font-size: rem(14px);
    text-transform: uppercase;
    text-align: center;
    span {
      display: block;
      flex: 1 0 auto;
      height: 1px;
      background: #000;
      &:first-of-type {
        margin-right: rem(10px);
      }
      &:last-of-type {
        margin-left: rem(10px);
      }
    }
  }
  &__text {
    margin-top: rem(0px);
    text-align: center;
    font-family: $reg;
    font-size: rem(20px);
    line-height: 1;
    b {
      display: block;
      text-transform: uppercase;
    }
    ul {
      margin: rem(10px) 0;
      li {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: rem(14px);
        font-size: rem(18px);
        text-align: left;
        &:not(:last-of-type) {
          margin-bottom: rem(14px);
        }
      }
      i {
        display: flex;
        align-items: center;
        justify-content: center;
        @include size(rem(30px));
        margin-right: rem(14px);
        line-height: 1;
        flex-shrink: 0;
        font-style: normal;
        font-family: $bold;
        font-size: rem(14px);
        background: #deb22e;
        border-radius: 50%;
      }
    }
  }
  &-signup {
    width: rem(758px);
  }

  &__another-option {
    margin: rem(20px) 0 rem(27px);
    font-family: $bold;
    font-size: rem(16px);
    text-transform: uppercase;
    text-align: center;
    a {
      border-bottom: 1px solid currentColor;
      &:hover {
        border-color: transparent;
      }
    }
  }
  &__footer {
    margin-top: rem(20px);
    text-align: center;
  }
  &__prize {
    display: flex;
    align-items: center;
    margin-bottom: rem(20px);
    &-img {
      width: 100%;
      height: auto;
      margin-right: rem(20px);
      flex-shrink: 0;
      &-big {
        height: rem(140px);
        margin-right: auto;
        margin-left: auto;
      }
    }
    &-text {
      color: $brown;
      font-size: rem(18px);
      line-height: rem(20px);
      text-transform: uppercase;
    }
  }
  .success_modal {
    &__text {
      margin-top: 0;
      font-size: rem(14px);
      margin-bottom: rem(20px);
      text-align: center !important;
      font-family: $reg;
      text-transform: none;
    }
    &-back {
      font-size: rem(12px);
      text-transform: uppercase;
      font-family: $bold;
      text-align: center;
    }
    &-image {
      margin: auto;
    }
  }
  .error_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: rem(14px);
    img {
      margin-bottom: rem(24px);
    }
    &-text {
      font-family: $reg;
      font-size: rem(14px);
      text-transform: none;
      margin-bottom: rem(25px);
    }
  }
  .btn {
    margin-left: auto;
    margin-right: auto;
  }
  &__popover-text {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: rem(20px);
    background: rgba($light, 0.6);
    color: $red;
    transform: translateY(-40px);
    transition: 0.6s ease-in-out;
    &:hover {
      transform: translateY(-100%);
    }
  }
  &-image {
    padding-top: rem(120px);
    padding-bottom: rem(55px);
  }
  &__iframe {
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: hidden;
    margin-bottom: rem(30px);
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: $sm) {
    min-height: auto;
    margin-top: 10vh;
    justify-content: flex-start;
    &--sm {
      width: rem(440px);
    }
    &--md {
      width: rem(700px);
    }
    &--lg {
      width: rem(758px);
      padding: rem(40px) rem(25px) rem(46px);
    }
  }
  @media (min-width: $md) {
    min-height: auto;
    top: 0;
    bottom: auto;
    justify-content: flex-start;
    border-radius: rem(25px);
    padding: rem(75px) rem(66px) rem(53px);
    &--md {
      width: rem(700px);
      padding: rem(50px) rem(120px);
    }
    &--lg {
      width: rem(800px);
      padding: rem(66px) rem(75px) rem(45px);
      .modal__subtitle {
        margin-bottom: rem(38px);
      }
      &.modal-image {
        margin-top: 10vh;
      }
      &.modal-video {
        width: 70vw;
      }
      &.modal-common_success,
      &.modal-common_error {
        padding-top: rem(100px);
      }
    }
    &-temporarily_unavailable {
      .modal__title {
        font-size: rem(40px);
      }
      .modal__text {
        font-size: rem(18px);
        line-height: 1.5;
        b {
          font-size: rem(26px);
          text-transform: uppercase;
        }
      }
    }
    &__title {
      margin-bottom: rem(30px);
      font-size: rem(50px);
      line-height: 0.9;
      letter-spacing: 0.02em;
    }
    &__text {
      // margin-top: rem(27px);
      font-size: rem(24px);
      ul {
        width: 70%;
        margin: rem(15px) auto 0;
        li {
          font-size: rem(24px);
        }
        i {
          @include size(rem(36px));
          font-size: rem(18px);
        }
      }
    }
    &__close {
      top: rem(20px);
    }
    &-pdf {
      max-height: 60vh;
    }
    &__list {
      width: 70%;
    }
    &__subtitle {
      margin-top: rem(-30px);
      margin-bottom: rem(20px);
      font-size: rem(14px);
    }
    &__another-option {
      margin: rem(30px) 0 0;
      font-size: rem(24px);
    }
    &__prize {
      width: rem(430px);
      margin: 0 auto rem(30px);
      &-img {
        width: rem(380px);
        margin: auto;
        & + .modal__prize-text {
          height: rem(150px);
          margin: 0;
        }
      }
      &-text {
        font-size: rem(22px);
        line-height: rem(25px);
      }
    }
    &__footer {
      width: rem(460px);
      max-width: 100%;
      margin: rem(30px) auto 0;
    }
  }
}
</style>
