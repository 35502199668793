<template>
  <form class="recover">
    <inputText
      label="новый пароль"
      type="password"
      maxlength="24"
      placeholder="••••••"
      :field="new_password"
      @input="new_password = $event"
      :class="{
        error: validationStatus($v.new_password) || errorMessage.new_password,
      }"
      :error="
        (!$v.new_password.required && $v.new_password.$error
          ? 'Обязательное поле'
          : !$v.new_password.minLength && $v.new_password.$error
          ? 'Минимум 6 символов'
          : !$v.new_password.maxLength && $v.new_password.$error
          ? 'Максимум 8 символа'
          : '') || errorMessage.new_password
      "
    />
    <inputText
      label="повторите пароль"
      type="password"
      maxlength="24"
      placeholder="••••••"
      :field="confirm_new_password"
      @input="confirm_new_password = $event"
      :class="{
        error:
          validationStatus($v.confirm_new_password) ||
          errorMessage.confirm_new_password,
      }"
      :error="
        (!$v.confirm_new_password.required && $v.confirm_new_password.$error
          ? 'Обязательное поле'
          : !$v.confirm_new_password.minLength && $v.confirm_new_password.$error
          ? 'Минимум 6 символов'
          : !$v.confirm_new_password.maxLength && $v.confirm_new_password.$error
          ? 'Максимум 24 символа'
          : '') || errorMessage.confirm_new_password
      "
    />

    <div class="recover__footer">
      <button
        type="button"
        class="btn btn--primary"
        @click="submit()"
        :class="{ loading: submitStatus == 'PENDING' }"
      >
        Отправить<img
          src="../../assets/img/spinner.svg"
          class="loader"
          alt=""
        />
      </button>
    </div>
  </form>
</template>

<script>
import inputText from "@/components/form/inputText.vue";

import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "RecoverForm",
  data() {
    return {
      ready: false,
      new_password: null,
      confirm_new_password: null,
      errorMessage: {
        new_password: null,
        confirm_new_password: null,
      },

      submitStatus: null,
    };
  },

  validations: {
    new_password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(24),
    },
    confirm_new_password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(24),
    },
  },

  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        new_password: null,
        confirm_new_password: null,
      };
    },

    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    setFormErrors(e) {
      const errors = e.response.data;
      this.submitStatus = null;

      let fieldsError = null;

      for (let index = 0; index < errors.length; index++) {
        const element = errors[index];
        this.errorMessage[element.field] = element.message;
        fieldsError = true;
      }

      if (errors && !fieldsError) {
        this.$modal.show("common_error", {
          text: "Что-то пошло не так! " + errors[0].message,
        });
      }
    },
    submit() {
      this.$v.$touch();

      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";

        this.$store
          .dispatch("UpdateProfile", {
            new_password: this.new_password,
            confirm_new_password: this.confirm_new_password,
          })
          .then((r) => {
            this.submitStatus = null;

            this.$modal.hide("change_password");
            this.$modal.show("common_success", {
              title: "изменение пароля",
              text: "ваш пароль изменен",
            });
          })
          .catch((e) => {
            console.log(e.response);
            if (e.response) {
              this.setFormErrors(e);
            }
          });
      }
    },
  },
  mounted() {},
  components: {
    inputText,
  },
};
</script>
