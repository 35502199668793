<template>
  <label class="form-textarea">
    <div class="form__caption" v-if="label">{{ label }}</div>
    <textarea
      class="form-textarea__field"
      :placeholder="placeholder || ' '"
      v-model.trim="retValue"
      :name="name"
      @focus="errorReset()"
      @input="onInput()"
      @blur="onBlur()"
    ></textarea>

    <div class="error-hint" v-if="error">{{ error | typograf }}</div>
  </label>
</template>
<script>
export default {
  data() {
    return {
      retValue: this.field,
      nameValue: this.name || "",
    };
  },
  methods: {
    errorReset() {
      this.$parent.errorReset();
    },

    onInput() {
      this.$emit("input", this.retValue);
    },

    onBlur() {
      this.$emit("input", this.retValue);
    },
  },
  props: {
    field: String,
    name: String,
    label: {
      type: String,
    },
    error: {
      type: String,
    },
    value: [String, Number],
    placeholder: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
$color: $red;
.form-textarea {
  position: relative;
  display: block;
  max-width: 100%;
  margin: 0 auto rem(20px);

  &__field {
    resize: none;
    width: 100%;
    padding: rem(15px) rem(23px) 0;
    max-width: 100%;
    height: rem(72px);
    appearance: none;
    font-size: $field-font-size-sm;
    background: #fff;
    font-family: $reg;
    border: $field-border;
    border-radius: $field-border-radius-sm;
    transition: all 0.3s ease-in-out;
    color: $field-color;
    text-transform: none;
    outline: none !important;
    &::placeholder {
      color: $field-placeholder;
      font-size: $field-font-size-sm;
    }
  }
  @media (min-width: $md) and (orientation: landscape) {
    &__field {
      height: rem(120px);
      padding: $field-padding-lg;
      font-size: $field-font-size-lg;
      border-radius: $field-border-radius-lg;
      &::placeholder {
        font-size: $field-font-size-lg;
      }
      ._big & {
        height: rem(180px);
      }
    }
  }
}
</style>
