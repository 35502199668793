import { render, staticRenderFns } from "./NewPasswordForm.vue?vue&type=template&id=382ba5b4&scoped=true"
import script from "./NewPasswordForm.vue?vue&type=script&lang=js"
export * from "./NewPasswordForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "382ba5b4",
  null
  
)

export default component.exports