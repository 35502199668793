var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitSendCode()}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNumber),expression:"phoneNumber"}],attrs:{"type":"hidden","name":"phone"},domProps:{"value":(_vm.phoneNumber)},on:{"input":function($event){if($event.target.composing)return;_vm.phoneNumber=$event.target.value}}}),_c('inputText',{class:{
        error:
          _vm.validationStatus(_vm.$v.code) ||
          _vm.errorMessage.phoneNumber ||
          _vm.errorMessage.code,
      },attrs:{"field":_vm.code,"size":"lg","error":(!_vm.$v.code.required && _vm.$v.code.$error
          ? 'Обязательное поле'
          : !_vm.$v.code.minLength && _vm.$v.code.$error
          ? 'Минимум ' + _vm.$v.code.$params.minLength.min + ' символов'
          : !_vm.$v.code.maxLength && _vm.$v.code.$error
          ? 'Максимум ' + _vm.$v.code.$params.maxLength.max + ' символов'
          : _vm.errorMessage.code) || _vm.errorMessage.phoneNumber},on:{"input":function($event){_vm.code = $event}}}),_c('div',{staticClass:"recover__footer"},[_c('button',{staticClass:"btn btn--primary",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"submit"}},[_vm._v(" Отправить ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }