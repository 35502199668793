<template>
  <form class="authorization__signin">
    <div class="authorization__signin-fields">
      <inputText
        label="E-mail"
        type="email"
        :field="email"
        @input="email = $event"
        :class="{
          error: validationStatus($v.email) || errorMessage.email,
        }"
        :error="
          (!$v.email.required && $v.email.$error
            ? 'Обязательное поле'
            : !$v.email.email && $v.email.$error
            ? 'Некорректный e-mail'
            : '') || errorMessage.email
        "
      />
      <inputText
        label="Пароль"
        type="password"
        :field="password"
        :class="{
          error: validationStatus($v.password) || this.errorMessage.password,
        }"
        :error="
          (!$v.password.required && $v.password.$error
            ? 'Обязательное поле'
            : !$v.password.minLength && $v.password.$error
            ? 'Минимум ' + $v.password.$params.minLength.min + ' символов'
            : '') || this.errorMessage.password
        "
        @input="password = $event"
      />
      <div class="authorization__row">
        <checkBox
          label="Запомнить меня"
          :field="checkbox"
          class="authorization__checkbox _white"
          @input="checkbox = $event"
        />
        <div
          class="forget-link _red _undeline-link"
          @click.prevent="switchToRecover()"
        >
          Забыли пароль?
        </div>
      </div>

      <div class="authorization__signin-footer">
        <button
          type="button"
          class="btn btn--primary btn--icon"
          @click="submit()"
          :class="{ loading: submitStatus == 'PENDING' }"
        >
          <span>Войти</span>

          <img src="../../assets/img/spinner.svg" class="loader" alt="" />
        </button>
        <div class="modal__another-option">
          <span>нет аккаунта? </span>
          <span @click="showSignUpModal" class="_undeline-link _red">
            Зарегистрируйтесь
          </span>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import CheckBox from "./checkBox.vue";
import inputText from "@/components/form/inputText.vue";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  name: "LoginForm",
  props: ["userPhone"],
  data() {
    return {
      domain: "https://national.emlsdr.ru/",
      ready: false,
      email: null,
      password: null,
      checkbox: true,
      errorMessage: {
        email: null,
        password: null,
        checkbox: null,
      },

      submitStatus: null,
    };
  },

  validations: {
    email: { required, email },
    password: { required, minLength: minLength(4) },
  },

  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        email: null,
        password: null,
      };
    },

    close() {
      this.$store.commit("SHOW_POPUP_CONTENT", false);
    },
    switchToRecover() {
      this.$modal.hide("authorization");
      this.$modal.show("recover");
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    setFormErrors(e) {
      const errors = e.response.data;
      this.submitStatus = null;

      let fieldsError = null;

      for (let index = 0; index < errors.length; index++) {
        const element = errors[index];
        this.errorMessage[element.field] = element.message;
        fieldsError = true;
      }

      if (errors && !fieldsError) {
        this.$modal.show("common_error", {
          text: "Что-то пошло не так! " + errors[0].message,
        });
      }
    },

    submit() {
      this.$v.$touch();

      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";

        this.$store
          .dispatch("SignIn", {
            email: this.email,
            password: this.password,
          })
          .then((r) => {
            console.log(r);
            this.submitStatus = null;

            this.$store.commit("SET_TOKEN", r);
            // this.gtmEvent("enter", "form", "send");
            // this.$metrika.reachGoal("enter");
            // this.gtmEvent("cabinet_success", "do", "internal");

            this.$store.dispatch("GetProfile").then((res) => {
              this.$root.$emit("onLogined");
              this.$modal.hide("authorization");
              this.$router.push("/profile");
            });
          })
          .catch((e) => {
            console.log(e.response);
            if (e.response) {
              this.setFormErrors(e);
            }
          });
      }
    },
    showSignUpModal() {
      this.$modal.hide("authorization");
      this.$modal.show("signup");
      // this.$modal.show("temporarily_unavailable");
    },
  },

  mounted() {
    this.phone = this.userPhone || null;
  },
  components: {
    inputText,
    CheckBox,
  },
};
</script>

<style lang="scss" scoped>
.authorization {
  h3 {
    text-align: center;
    margin-top: 8px;
  }

  &__signin {
    width: 100%;
    margin: 0 auto;
    &-fields {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    &-footer {
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;
      .btn {
        width: 100%;
      }
    }
    .forget-link {
      white-space: nowrap;
      font-size: rem(18px);
      text-align: right;
      b {
        border-bottom: 1px solid currentColor;
      }
    }

    .forget-link:hover {
      text-decoration: none;
    }
  }
  &__checkbox {
    white-space: nowrap;
  }
  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: rem(20px);
  }
  @media (min-width: $md) {
    &__checkbox {
      margin-bottom: rem(15px);
    }
    &__signin {
      .forget-link {
        margin: 0 0 rem(34px);
        font-size: rem(20px);
        line-height: rem(20px);
      }
      &-footer {
        width: 100%;
        flex-direction: column;
        .btn {
          min-width: auto;
          width: 100%;
          &:not(:last-of-type) {
            margin-bottom: rem(30px);
          }
        }
      }
    }
    &__row {
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: rem(0px);
    }
  }
}
</style>
