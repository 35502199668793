<template>
  <Modal
    name="prize_popup-4"
    size="lg"
    :title="prize === 5 ? 'ваш гарантированный приз' : 'Вы выиграли приз!'"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <div class="modal__prize">
        <img
          v-if="prize"
          class="modal__prize-img modal__prize-img"
          :src="
            require(`../../assets/images/prizes/user-prizes/prize_${prize}-big.png`)
          "
          alt=""
        />
      </div>
      <div class="modal__text">
        <b>{{ prizeTitle }}</b>
      </div>

      <div class="modal__footer">
        <a class="btn btn--primary btn--download w-100" href="">Скачать</a>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      prize: 0,
      prizeTitle: null,
    };
  },

  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen(event) {
      this.prize = event.params.prize;
      this.prizeTitle = event.params.prizeTitle;
    },
  },
};
</script>

<style lang="scss" scoped></style>
