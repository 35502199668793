<template>
  <div class="form-page-form__block">
    <form @submit.prevent="submitSendCode()">
      <inputText
        label="Новый пароль"
        placeholder="••••"
        type="password"
        :field="new_password"
        autocomplete="new-password"
        :class="{
          error: validationStatus($v.new_password) || errorMessage.new_password,
        }"
        :error="
          (!$v.new_password.required && $v.new_password.$error
            ? 'Обязательное поле'
            : !$v.new_password.minLength && $v.new_password.$error
            ? 'Минимум ' + $v.new_password.$params.minLength.min + ' символов'
            : '') || errorMessage.new_password
        "
        @input="new_password = $event"
      />
      <inputText
        label="Повторите пароль"
        placeholder="••••"
        type="password"
        :field="confirm_new_password"
        autocomplete="new-password"
        :class="{
          error:
            validationStatus($v.confirm_new_password) ||
            this.errorMessage.confirm_new_password,
        }"
        :error="
          (!$v.confirm_new_password.required && $v.confirm_new_password.$error
            ? 'Обязательное поле'
            : !$v.confirm_new_password.minLength &&
              $v.confirm_new_password.$error
            ? 'Минимум ' +
              $v.confirm_new_password.$params.minLength.min +
              ' символов'
            : '') || errorMessage.confirm_new_password
        "
        @input="confirm_new_password = $event"
      />
      <div class="modal__footer">
        <button
          class="btn btn--primary w-100"
          type="submit"
          :class="{ loading: submitStatus == 'PENDING' }"
        >
          отправить
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import inputText from "@/components/form/inputText.vue";

import {
  required,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";

export default {
  props: ["token"],
  components: {
    inputText,
  },
  data() {
    return {
      ready: false,
      new_password: null,
      confirm_new_password: null,
      errorMessage: {
        new_password: null,
        confirm_new_password: null,
      },

      submitStatus: null,
    };
  },
  validations: {
    new_password: {
      required,
    },
    confirm_new_password: {
      required,
      minLength: minLength(4),
      sameAsPassword: sameAs("new_password"),
    },
  },
  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        confirm_new_password: null,
        new_password: null,
      };
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    setFormErrors(e) {
      const errors = e.response.data;
      this.submitStatus = null;

      let fieldsError = null;

      for (let index = 0; index < errors.length; index++) {
        const element = errors[index];
        this.errorMessage[element.field] = element.message;
        fieldsError = true;
      }

      if (errors && !fieldsError) {
        this.$modal.show("common_error", {
          text: "Что-то пошло не так! " + errors[0].message,
        });
      }
    },
    submitSendCode() {
      console.log("submit");
      this.$v.$touch();

      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";
        const formData = this.formData;
        this.$store
          .dispatch("ResetPassword", {
            new_password: this.new_password,
            confirm_new_password: this.confirm_new_password,
          })
          .then((r) => {
            this.submitStatus = null;
            this.$modal.hide("new-password");
            this.$modal.show("common_success", {
              title: "изменение пароля",
              text: "Ваш пароль успешно изменен",
            });
            this.$store.dispatch("GetProfile").then((res) => {
              this.$root.$emit("onLogined");
            });
          })
          .catch((e) => {
            console.log(e.response);
            if (e.response) {
              this.setFormErrors(e);
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
