<template>
  <label
    class="form__field"
    :class="[
      color === 'white'
        ? 'form__field--white'
        : '' || color === 'green'
        ? 'form__field--green'
        : '',
      size ? 'form__field--' + size : '',
    ]"
  >
    <div class="form__caption" v-if="label" v-html="label"></div>
    <input
      v-if="maskValue != ''"
      :disabled="isDisabled"
      :type="fieldType"
      :value="value"
      :id="fieldId"
      :maxlength="maxlength"
      :autocomplete="autocomplete"
      :autocompletetype="autocompletetype"
      :placeholder="placeholder || ' '"
      class="form__field-input"
      :class="[
        size ? 'form__field-input--' + size : '',
        placeholder ? '_has-placeholder' : '',
      ]"
      v-model.trim="retValue"
      v-mask="maskValue"
      :name="name"
      @focus="errorReset()"
      @input="onInput()"
      @blur="onBlur()"
    />
    <input
      v-else
      :disabled="isDisabled"
      :type="fieldType"
      :id="fieldId"
      :autocomplete="autocomplete"
      :autocompletetype="autocompletetype"
      :value="value"
      :maxlength="maxlength"
      :placeholder="placeholder || ' '"
      class="form__field-input"
      :class="size ? 'form__field-input--' + size : ''"
      v-model.trim="retValue"
      :name="name"
      @focus="errorReset()"
      @input="onInput()"
      @blur="onBlur()"
    />

    <div v-if="type === 'password'" @click="toggleType" class="form__toggle">
      <img
        v-if="fieldType === 'password'"
        src="../../assets/images/icons/eye.svg"
        alt=""
      />
      <img v-else src="../../assets/images/icons/eye-off.svg" alt="" />
    </div>
    <div class="error-hint" v-if="error">{{ error | typograf }}</div>
    <svg
      v-if="error"
      class="form__error-icon"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 11L11 1" stroke="#B33015" stroke-width="2" />
      <path d="M11 11L1 1" stroke="#B33015" stroke-width="2" />
    </svg>

    <slot></slot>
  </label>
</template>
<script>
export default {
  data() {
    return {
      fieldType: "text",
      retValue: this.field,
      maskValue: this.mask || "",
      nameValue: this.name || "",
    };
  },
  mounted() {
    this.fieldType = this.type;
  },
  methods: {
    errorReset() {
      this.$parent.errorReset();
    },

    onInput() {
      this.$emit("input", this.retValue);
    },

    onBlur() {
      this.$emit("input", this.retValue);
    },
    toggleType() {
      if (this.fieldType === "password") {
        this.fieldType = "text";
      } else {
        this.fieldType = "password";
      }
    },
  },
  props: {
    field: String,
    fieldId: String,
    mask: String,
    name: String,
    autocomplete: String,
    autocompletetype: String,
    maxlength: {
      type: [String, Number],
    },
    label: {
      type: String,
    },
    error: {
      type: [String, Boolean],
    },
    value: [String, Number],
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default: () => {
        return "text";
      },
    },
    isDisabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    size: {
      type: String,
      default: () => {
        return null;
      },
    },
    color: {
      type: String,
      default: () => {
        return "white";
      },
    },
  },

  components: {},
};
</script>
<style lang="scss">
.form__field-input,
.dadata-input.form__field-input {
  width: 100%;
  height: $field-height-sm;
  padding: $field-padding-sm;
  max-width: 100%;
  appearance: none;
  font-size: $field-font-size-sm;
  background: #ffffff;
  border-radius: $field-border-radius-sm;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  border: $field-border;
  color: $field-color;
  font-family: $reg;
  outline: none !important;
  ._centered-text & {
    text-align: center;
  }
  ._bordered & {
    height: rem(56px);
    text-transform: uppercase;
    background: #fff2e5;
    border: 1px solid $red;
  }
  &::placeholder {
    color: $field-placeholder;
    font-size: $field-font-size-sm;
    ._bordered & {
      color: $red;
      font-family: $reg;
      opacity: 1;
    }
  }
  &:focus {
    &::placeholder {
      color: transparent;
    }
  }
  &._has-placeholder {
    outline: none;
    // & ~ .form__caption {
    //   transform: scale(0.6) translateY(#{rem(-10px)});
    //   @media (min-width: $md) and (orientation: landscape) {
    //     transform: scale(0.6) translateY(#{rem(-13px)});
    //   }
    // }
  }
  &:disabled {
    pointer-events: none;
    background: #d9d9d9;
    border-color: #d9d9d9;
  }
  &--lg {
    height: rem(70px);
    padding: 0 rem(30px);
    font-weight: 400;
    font-size: rem(20px);
    &::placeholder {
      font-weight: 400;
      font-size: rem(20px);
    }
  }
  @media (min-width: $md) and (orientation: landscape) {
    height: $field-height-lg;
    padding: $field-padding-lg;
    font-size: $field-font-size-lg;
    border-radius: $field-border-radius-lg;
    &::placeholder {
      font-size: $field-font-size-lg;
    }
    &--lg {
      height: rem(70px);
      padding: rem(0px) rem(25px) 0 rem(32px);
      & + .form__caption {
        top: rem(25px);
      }
    }
  }
}
</style>
