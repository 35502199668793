<template>
  <Modal
    name="common_error_upload"
    size="md"
    title="добавление фото"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <div class="error_modal">
        <img src="../../assets/images/error.svg" alt="" />
        <div class="error_modal-text" v-if="text" v-html="text"></div>
        <button
          class="btn btn--primary w-100"
          @click="
            $modal.hide('common_error_upload'), $modal.show('upload_photo')
          "
        >
          отправить другое фото
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      text: "",
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },

    beforeOpen(event) {
      this.text = event.params.text;
    },
  },
};
</script>
<style lang="scss" scoped>
.common-error-list {
  list-style: auto;
  list-style-position: inside;
}
</style>
