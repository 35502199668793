<template>
  <section class="prizes section" id="prizes">
    <div class="container fxdc">
      <div class="section-title _white">ПРИЗЫ</div>
      <div class="prizes__content fxdc">
        <div class="prizes__list">
          <div v-for="(item, i) in prizes" :key="i" class="prizes-item">
            <div class="prizes-item__content">
              <div class="prizes-item-img-wrapper">
                <img
                  :src="require(`../assets/images/prizes/${item.img}`)"
                  class="prizes-item-img hidden-xs"
                />
                <img
                  :src="require(`../assets/images/prizes/${item.imgM}`)"
                  class="prizes-item-img visible-xs"
                />
              </div>
              <div class="prizes-item__text" v-html="item.text"></div>
              <div class="btn btn--yellow">{{ item.type }}</div>
            </div>
          </div>
        </div>
        <div class="prizes-main-prize">
          <img
            src="../assets/images/prizes/main-prize.svg"
            alt=""
            class="prizes-main-prize__bg hidden-xs"
          />
          <div class="prizes-main-prize__content">
            <img
              src="../assets/images/animations/plane.svg"
              alt=""
              class="prizes-main-prize__plane visible-xs"
            />
            <div class="prizes-main-prize__suptitle">
              Путешествие <br />с видом на горы
            </div>
            <div class="prizes-main-prize__text">для всей семьи на сумму</div>
            <div class="prizes-main-prize__sum">300 000 ₽</div>
            <div class="btn btn--yellow">главный приз</div>
          </div>
        </div>
        <div class="prizes-special-prize">
          <div class="prizes-special-prize__title">специальный розыгрыш</div>
          <div class="prizes-special-prize__content">
            <div class="prizes-special-prize__row">
              <div class="prizes-special-prize__action">
                <svg
                  width="66"
                  height="53"
                  viewBox="0 0 66 53"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="0.187256"
                    width="65.8919"
                    height="52"
                    rx="26"
                    fill="#FDCB33"
                  />
                  <path
                    d="M31.4983 34.1873V22.7473H29.4083V19.5133H35.3043V34.1873H31.4983Z"
                    fill="#5D2723"
                  />
                </svg>
                <span>
                  Купите 1&nbsp;любую
                  <br />упаковку <br />из&nbsp;новой <br />коллекции
                </span>
              </div>
              <div class="prizes-special-prize__product">
                <img src="../assets/images/product-1.png" alt="" />
                <span
                  >&laquo;Принцесса Нури&raquo; <br />Высокогорный
                  с&nbsp;чабрецом</span
                >
              </div>
              <div class="prizes-special-prize__product">
                <img src="../assets/images/product-2.png" alt="" />
                <span
                  >&laquo;Принцесса Нури&raquo; <br />Высокогорный
                  с&nbsp;мятой</span
                >
              </div>
            </div>
            <div class="prizes-special-prize__row">
              <div class="prizes-special-prize__action">
                <svg
                  width="66"
                  height="53"
                  viewBox="0 0 66 53"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="0.187256"
                    width="65.8919"
                    height="52"
                    rx="26"
                    fill="#FDCB33"
                  />
                  <path
                    d="M38.6923 30.9533V34.1873H26.9223L30.6403 30.2933C31.9749 28.8999 32.9796 27.6606 33.6543 26.5753C34.1823 25.7539 34.4463 24.9913 34.4463 24.2873C34.4463 23.6713 34.2996 23.2019 34.0063 22.8793C33.7423 22.5859 33.3829 22.4393 32.9283 22.4393C32.3856 22.4393 31.9603 22.6373 31.6523 23.0333C31.3296 23.4439 31.1536 24.0453 31.1243 24.8373H27.1863C27.2009 23.1946 27.7436 21.8379 28.8143 20.7673C29.8703 19.7113 31.2563 19.1833 32.9723 19.1833C34.7176 19.1833 36.0963 19.6893 37.1083 20.7013C38.0616 21.6546 38.5383 22.8573 38.5383 24.3093C38.5383 25.8639 37.8123 27.3159 36.3603 28.6653L33.8963 30.9533H38.6923Z"
                    fill="#5D2723"
                  />
                </svg>
                <span>
                  станьте участником <br />розыгрыша сертификата
                  <br />&laquo;Монетка&raquo; на&nbsp;4000&nbsp;₽ <br /><small
                    >20 шт.
                  </small>
                </span>
              </div>
              <img
                src="../assets/images/card.png"
                alt=""
                class="prizes-special-prize__card"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="prizes__plane">
      <img src="../assets/images/animations/plane.svg" alt="" />
    </div>
    <div class="prizes__clouds">
      <img
        src="../assets/images/animations/cloud-4.svg"
        class="prizes__cloud"
        alt=""
      />
      <img
        src="../assets/images/animations/cloud-5.svg"
        class="prizes__cloud"
        alt=""
      />
      <img
        src="../assets/images/animations/cloud-6.svg"
        class="prizes__cloud"
        alt=""
      />
      <img
        src="../assets/images/animations/cloud-5.svg"
        class="prizes__cloud"
        alt=""
      />
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  props: {},
  data: function () {
    return {
      prizes: [
        {
          img: "prize-1.png",
          imgM: "prize-1-m.png",
          text: "30&nbsp;рублей <br class='hidden-xs'>на&nbsp;телефон",
          type: "гарантированно",
        },
        {
          img: "prize-2.png",
          imgM: "prize-2-m.png",
          text: "Подарочный набор: <br>чай, плед, настольная игра",
          type: "ежедневно",
        },
        {
          img: "prize-3.png",
          imgM: "prize-3-m.png",
          text: "Современная <br class='hidden-xs'>семейная палатка",
          type: "еженедельно",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.prizes {
  position: relative;
  align-items: center;
  margin-top: rem(-48px);
  padding-top: rem(55px);
  padding-bottom: rem(260px);
  flex-direction: column;
  background: url("../assets/images/prizes/bg-m.png") no-repeat center top;
  background-size: 100%;
  z-index: 2;
  &__content {
  }
  .container {
    position: relative;
    z-index: 1;
  }
  &__list {
    display: flex;
    flex-direction: column;
  }
  .section-title {
    margin-bottom: rem(46px);
  }
  &-item {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 rem(10px) rem(10px);
    margin-bottom: rem(49px);
    background: #fff;
    border-radius: rem(20px);
    &:last-of-type {
      margin-bottom: 0;
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-img {
      height: 100%;
      &._alsfe {
        align-self: flex-end;
      }
      &-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: rem(-30px) auto rem(20px);
        ._big & {
          position: absolute;
          top: rem(-25px);
          left: 50%;
          margin: 0;
          transform: translate(-50%, -50%);
          height: rem(112px);
        }
      }
    }
    &__text {
      margin-bottom: rem(20px);
      font-size: rem(20px);
      line-height: 1;
      text-align: center;
    }
    .btn {
      min-width: auto;
      margin-top: auto;
      pointer-events: none;
      cursor: default;
    }
  }
  &-main-prize {
    position: relative;
    width: 100%;
    margin: rem(68px) auto rem(30px);
    &__plane {
      width: rem(260px);
      margin-top: rem(-50px);
      margin-bottom: rem(12px);
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: rem(10px);
      align-items: center;
      text-align: center;
      background: #fff;
      border-radius: rem(20px);
    }
    &__suptitle {
      line-height: 1;
      font-family: $bold;
      text-transform: uppercase;
      font-size: rem(20px);
    }
    &__text {
      font-size: rem(20px);
    }
    &__sum {
      margin-bottom: rem(5px);
      font-family: $bold;
      font-size: rem(60px);
      line-height: 1;
      text-transform: uppercase;
    }
  }
  &-special-prize {
    width: rem(820px);
    max-width: 100%;
    margin: auto;
    &__title {
      margin-bottom: rem(15px);
      font-size: rem(30px);
      font-family: $alt-font;
      line-height: 1;
      letter-spacing: 0.03em;
      text-align: center;
    }
    &__content {
      position: relative;
      background: #fff;
      padding: rem(20px) rem(16px);
      border-radius: rem(20px);
    }
    &__action {
      width: 100%;
      display: flex;
      margin-bottom: rem(20px);
      font-family: $bold;
      text-transform: uppercase;
      font-size: rem(20px);
      line-height: rem(21px);
      svg {
        width: rem(48px);
        margin-right: rem(12px);
        flex-shrink: 0;
      }
      span {
        display: block;
        flex-grow: 0;
      }
      small {
        display: block;
        font-family: $reg;
        text-transform: none;
      }
    }
    &__product {
      display: flex;
      margin-bottom: rem(20px);
      padding-left: rem(15px);
      font-size: rem(18px);
      line-height: rem(18px);
      img {
        height: rem(140px);
      }
      span {
        display: block;
        margin-left: rem(-42px);
      }
    }
    &__card {
      width: rem(225px);
      height: auto;
      margin-left: auto;
    }
  }
  &__plane {
    position: absolute;
    left: -25%;
    bottom: 10%;
    width: rem(200px);
    .active & {
      animation: plane 10s ease-in;
    }
  }
  &__cloud {
    position: absolute;
    display: block;
    z-index: 0;
    &:nth-of-type(1) {
      top: rem(115px);
      left: 6%;
      height: rem(90px);
      animation: cloud 25s linear infinite;
    }
    &:nth-of-type(2) {
      top: rem(350px);
      left: 3%;
      height: rem(60px);
      animation: cloud 55s linear infinite;
    }
    &:nth-of-type(3) {
      top: rem(620px);
      left: 60%;
      height: rem(70px);
      animation: cloud2 35s linear infinite;
    }
    &:nth-of-type(4) {
      top: rem(520px);
      left: 60%;
      height: rem(90px);
      animation: cloud2 25s linear infinite;
    }
  }
  @media (min-width: $sm) {
    margin-top: rem(-97px);
    padding-top: rem(165px);
    padding-bottom: rem(509px);
    background: url("../assets/images/prizes/bg.png") no-repeat center top;
    background-size: 100%;
    overflow: visible;
    z-index: 1;
    .container {
      display: flex;
      padding-top: rem(0px);
    }
    .section-title {
      margin-bottom: rem(83px);
    }
    &__plane {
      left: -25vw;
      bottom: 50%;
      width: rem(472px);
    }
    &__content {
      flex-direction: column;
      align-items: center;
      padding-bottom: 0;
      padding-top: 0;
    }
    &__text {
      font-size: rem(18px);
      line-height: rem(25px);
      text-align: center;
    }
    &__list {
      width: 95%;
      margin: 0 auto rem(90px);
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    &-item {
      flex-direction: row;
      flex: 0 0 26.5%;
      height: rem(404px);
      margin: 0 rem(45px) 0;
      padding: rem(10px) rem(10px) rem(34px);
      max-height: 100%;
      border-radius: rem(30px);
      &._big {
        flex: 0 0 36%;
        height: auto;
        padding-top: rem(156px);
        padding-bottom: rem(38px);
        order: 0;
      }
      &__content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      &-img {
        width: auto;
        height: 100%;
        &-wrapper {
          width: 100%;
          margin: rem(-45px) auto rem(35px);
          ._big & {
            position: absolute;
            top: 4%;
            left: 50%;
            margin: 0;
            transform: translate(-50%, -50%);
            height: rem(175px);
          }
        }
      }

      &__text {
        max-width: 80%;
        margin: 0 auto rem(6px);
        font-size: rem(26px);
        line-height: 1;
        text-align: center;
      }
      .btn {
        margin-top: auto;
        pointer-events: none;
        cursor: default;
      }
    }
    &-main-prize {
      position: relative;
      width: rem(820px);
      margin: 0 auto rem(80px);
      &__content {
        position: absolute;
        top: 0;
        left: rem(60px);
        width: rem(470px);
        height: 100%;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &__suptitle {
        font-size: rem(36px);
      }
      &__text {
        font-size: rem(26px);
      }
      &__sum {
        margin-bottom: rem(15px);
        font-size: rem(100px);
        line-height: 1;
      }
    }
    &-special-prize {
      &__title {
        font-size: rem(50px);
      }
      &__content {
        padding: rem(37px) rem(20px) rem(25px) rem(30px);
        border-radius: rem(30px);
      }
      &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:first-of-type {
          padding-right: rem(30px);
          padding-bottom: rem(40px);
        }
      }
      &__action {
        width: auto;
        max-width: 40%;
        margin-bottom: 0;
        flex-direction: column;
        font-size: rem(24px);
        line-height: rem(25px);
        white-space: nowrap;
        svg {
          display: block;
          width: rem(66px);
          margin-bottom: rem(14px);
          margin-right: 0;
        }
        small {
          margin-top: rem(10px);
          font-size: rem(26px);
        }
      }
      &__product {
        flex-direction: column;
        margin-bottom: 0;
        padding-left: 0;
        text-align: center;
        font-size: rem(20px);
        line-height: 1;
        img {
          height: rem(169px);
          margin-bottom: rem(6px);
        }
      }
      &__card {
        width: rem(294px);
        height: auto;
        margin-left: auto;
      }
    }
    &__cloud {
      position: absolute;
      z-index: 0;
      &:nth-of-type(1) {
        top: rem(1100px);
        left: -30vw;
        height: rem(198px);
        animation: cloudBig 40s linear infinite;
        transform: translateX(-100%);
      }
      &:nth-of-type(2) {
        top: rem(1000px);
        left: -30vw;
        height: rem(147px);
        animation: cloudBig 25s linear infinite;
        transform: translateX(-100%);
      }
      &:nth-of-type(3) {
        top: rem(800px);
        left: 100%;
        height: rem(198px);
        animation: cloudBigRight 45s linear infinite;

        transform: translateX(100%);
      }
      &:nth-of-type(4) {
        top: rem(950px);
        left: 10%;
        height: rem(198px);
        animation: cloudBigRight 25s linear infinite;
        transform: translateX(100%);
      }
    }
  }
}

@keyframes plane {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(125vw, rem(-800px));
  }
}
</style>
