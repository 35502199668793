<template>
  <div class="feedback">
    <form class="feedback__form" :key="indexForm">
      <inputText
        label="E-mail"
        :field="email"
        @input="email = $event"
        :class="{
          error: validationStatus($v.email) || errorMessage.email,
        }"
        :error="
          (!$v.email.required && $v.email.$error
            ? 'Обязательное поле'
            : !$v.email.email && $v.email.$error
            ? 'Некорректный e-mail'
            : '') || errorMessage.email
        "
      />
      <inputText
        label="Имя"
        :field="name"
        @input="name = $event"
        :class="{ error: validationStatus($v.name) || errorMessage.name }"
        :error="
          (!$v.name.required && $v.name.$error
            ? 'Обязательное поле'
            : !$v.name.rus && $v.name.$error
            ? 'Введите текст на русском языке'
            : '') || errorMessage.name
        "
      />
      <Select
        color="grey"
        :options="[
          { id: 1, label: 'Регистрация в акции' },
          { id: 2, label: 'Регистрация чека' },
          { id: 3, label: 'Призовой фонд' },
          { id: 4, label: 'Вопросы по правилам' },
          { id: 5, label: 'Технические вопросы' },
          { id: 6, label: 'Другое' },
        ]"
        labelText="Тема"
        @input="subject = $event"
        :class="{
          error: validationStatus($v.subject) || errorMessage.subject,
        }"
        :error="
          (!$v.subject.required && $v.subject.$error
            ? 'Обязательное поле'
            : '') || errorMessage.subject
        "
      />
      <textArea
        label="Текст обращения"
        :field="text"
        :key="indexForm2"
        @input="text = $event"
        :class="{
          error: validationStatus($v.text) || errorMessage.text,
        }"
        :error="
          (!$v.text.required && $v.text.$error ? 'Обязательное поле' : '') ||
          errorMessage.text
        "
      />
      <div class="feedback__checkbox_list">
        <checkBox
          :label="`Я даю согласие на&nbsp;обработку <a href='${publicPath}docs/terms-of-use.pdf' target='_blank' class='_red'>персональных данных</a>`"
          :field="checkbox"
          :class="{
            error: validationStatus($v.checkbox) || this.errorMessage.checkbox,
          }"
          :error="this.errorMessage.checkbox"
          @input="checkbox = $event"
        />
      </div>
      <div class="modal__footer">
        <button
          type="button"
          class="btn btn--primary w-100"
          @click="submit(), gtmEvent('ask_question', 'click', 'internal')"
          :class="{ loading: submitStatus == 'PENDING' }"
        >
          <span>задать вопрос</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import inputText from "@/components/form/inputText.vue";
import checkBox from "@/components/form/checkBox.vue";
import textArea from "@/components/form/textArea.vue";
import Select from "@/components/form/inputSelect.vue";

import { required, email, sameAs } from "vuelidate/lib/validators";
const rus = (value) => /^[А-ЯЁа-яё ]+$/.test(value);

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  name: "FeedbackForm",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      indexForm: 1,
      indexForm2: 1,
      ready: false,
      name: null,
      email: null,
      text: null,
      subject: null,
      checkbox: false,
      attach: "",
      errorMessage: {
        email: null,
        name: null,
        text: null,
        subject: null,
        checkBox: null,
      },

      submitStatus: null,

      chosenFileName: {},
    };
  },

  validations: {
    text: { required },
    email: { required, email },
    subject: { required },
    name: { required, rus },
    checkbox: {
      required,
      sameAs: sameAs(() => true),
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  watch: {
    text(newVal) {
      if (newVal === null) {
        this.indexForm2++;
      }
    },
  },
  methods: {
    submitMedia(e) {
      let t = this;

      this.chosenFileName = e;

      if (this.chosenFileName.length) {
        getBase64(this.chosenFileName[0]).then(function (data) {
          t.attach = data;
        });
      }
    },
    deleteFile() {
      this.chosenFileName = {};
      this.attach = "";
    },

    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        email: null,
        name: null,
        text: null,
        subject_id: null,
        checkBox: null,
      };
    },

    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    setFormErrors(e) {
      const errors = e.response.data;
      this.submitStatus = null;

      let fieldsError = null;

      for (let index = 0; index < errors.length; index++) {
        const element = errors[index];
        this.errorMessage[element.field] = element.message;
        fieldsError = true;
      }

      if (errors && !fieldsError) {
        this.$modal.show("common_error", {
          text: "Что-то пошло не так! " + errors[0].message,
        });
      }
    },
    submit() {
      console.log("submit");
      this.$v.$touch();

      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";

        this.$store
          .dispatch("SendFeedback", {
            name: this.name,
            email: this.email,
            theme_id: this.subject,
            text: this.text,
            agree: "yes",
          })
          .then((r) => {
            console.log(r);

            this.indexForm++;
            this.indexForm2++;
            setTimeout(() => {
              this.$v.$reset();
              this.message = null;
            }, 0);
            this.submitStatus = null;
            this.$modal.hide("feedback");
            this.$modal.show("common_success", {
              title: "Спасибо за&nbsp;Ваше обращение!",
              text: `Оно будет обработано в&nbsp;течение 48 часов. Мы&nbsp;пришлем ответ&nbsp;на ${this.email}.`,
            });
          })
          .catch((e) => {
            this.submitStatus = null;
            console.log(e.response);
            if (e.response) {
              this.setFormErrors(e);
            }
          });
      }
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.user) {
        this.indexForm++;
        this.name = this.user.first_name;
        this.email = this.user.email;
      }
    }, 1000);
  },
  components: {
    inputText,
    textArea,
    checkBox,
    Select,
  },
};
</script>

<style lang="scss"></style>
